import { USER_ENUM } from "@/context/user"
import { API_BASE_URL } from "@/shared/API_BASE_URL"

interface DataType {
  timeOut?: number
  baseUrl?: string
  errorToLogin?: boolean
  token?: string
}

export default class RequestConstruct {
  private timeOut?: number = 300000
  private baseUrl?: string = API_BASE_URL
  private errorToLogin?: boolean = true

  constructor(data?: Partial<DataType>) {
    this.timeOut = data?.timeOut || 300000
    this.baseUrl = data?.baseUrl || API_BASE_URL
    this.errorToLogin = data?.errorToLogin ?? true
  }

  private parseUrl(url: string) {
    if (!/^http/gi.test(url)) url = `${this.baseUrl}${url}`

    return url
  }

  private getToken(): string {
    return `Bearer ${localStorage.getItem(USER_ENUM.TOKEN)}`
  }

  private generateHeaders(contentType?: "application/json"): Headers {
    const headers = new Headers()
    headers.set("Authorization", this.getToken())
    contentType && headers.set("Content-Type", contentType)
    return headers
  }

  public jsonRequest({
    body,
    url,
    header,
    method = "GET",
  }: {
    url: string
    method?: string
    header?: Record<string, any>
    body?: BodyInit
    redirect?: RequestRedirect
    showMessage?: boolean
  }) {
    url = this.parseUrl(url)

    const controller = new AbortController()

    setTimeout(() => {
      controller.abort()
    }, this.timeOut)

    const headers = this.generateHeaders("application/json")
    if (header) {
      for (const key in header) {
        const val = header[key]
        headers.append(key, val)
      }
    }

    return fetch(url, {
      body,
      headers,
      method,
      redirect: "manual",
      signal: controller.signal,
    })
      .then(res => res.json())
      .then(res => {
        if (res.code === 403 && this.errorToLogin) {
          localStorage.removeItem("user")
          window.location.href = "/login"
        }
        return res
      })
  }

  public textRequest({
    url,
    method = "GET",
    body,
  }: {
    url: string
    method?: string
    body?: string | FormData
  }) {
    url = this.parseUrl(url)
    const controller = new AbortController()

    setTimeout(() => {
      controller.abort()
    }, this.timeOut)

    return fetch(url, {
      body,
      headers: this.generateHeaders(),
      method,
      redirect: "follow",
      signal: controller.signal,
    }).then(res => res.json())
  }
}
