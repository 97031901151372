export const getBackgroundCls = () => `bg-zinc-50 dark:bg-zinc-950`
export const getSecondaryBackgroundCls = () =>
  `bg-zinc-300 dark:bg-neutral-800`
export const getReverseBackgroundCls = () =>
  `bg-zinc-950 dark:bg-zinc-50`
export const getDangerBackgroundCls = () =>
  `bg-red-700/50 backdrop-blur-2xl`
export const getAlphaBackgroundCls = () =>
  `bg-white/80 dark:bg-black/80`

export const getReverseBackgroundHoverCls = () =>
  `dark:hover:bg-white/90 hover:bg-black/80`

export const getTextColorCls = () => `text-black dark:text-white`
export const getReverseTextColorCls = () =>
  `text-white dark:text-black`
export const getSecondTextColorCls = () =>
  `text-zinc-700 dark:text-zinc-100`

export const getBorderCls = () =>
  `border-[1px] border-zinc-100 dark:border-zinc-700 `
export const getFocusBorderCls = () =>
  `border-2 border-transparent focus:border-black/40 dark:focus:border-white/40`

export const getRoundedCls = () => `rounded-lg`

export const getHoverShadowCls = () =>
  `shadow-lg shadow-transparent hover:shadow-neutral-600/30 hover:dark:shadow-neutral-200/30`

export const normalizeSize = (size: string | number) =>
  typeof size === "number" ? `${size}px` : size
