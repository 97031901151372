import { fetchIsAdminRequest } from "@/api/permission"
import { fetchUserById, fetchUserProfileInfo } from "@/api/user"
import React from "react"

export enum USER_ENUM {
  TOKEN = "USER_TOKEN",
  PROFILE = "USER_PROFILE",
  THEME = "USER_THEME",
}

const UserContext = React.createContext<{
  token: string | null
  theme: number
  profile: UserProfileType | null
  permission: UserPermissionType | null
  onFetchPermission: () => Promise<void>
  onGetProfile: (isForce?: boolean) => Promise<void>
  onSetToken: (val: string) => void
  onSetTheme: (val: number) => void
}>({
  token: null,
  profile: null,
  theme: 0,
  permission: null,
  onFetchPermission: () => Promise.resolve(),
  onGetProfile: () => Promise.resolve(),
  onSetToken: () => void 0,
  onSetTheme: () => void 0,
})

export function useUserContext() {
  const context = React.useContext(UserContext)
  if (!context) throw Error("userContext is not in its field")

  return context
}

interface UserProviderProps extends React.PropsWithChildren {}
export default function UserProvider(props: UserProviderProps) {
  const [token, setToken] = React.useState<string | null>(
    localStorage.getItem(USER_ENUM.TOKEN),
  )
  const [profile, setProfile] =
    React.useState<UserProfileType | null>(
      localStorage.getItem(USER_ENUM.PROFILE)
        ? JSON.parse(
            localStorage.getItem(USER_ENUM.PROFILE) as string,
          )
        : null,
    )
  const [permission, setPermission] =
    React.useState<UserPermissionType | null>(null)
  const [theme, setTheme] = React.useState<number>(
    localStorage.getItem(USER_ENUM.THEME)
      ? Number(localStorage.getItem(USER_ENUM.THEME))
      : 0,
  )

  const onGetProfile = React.useCallback(
    async (isForce: boolean = false) => {
      if (!isForce && profile) return
      else {
        const res = await fetchUserProfileInfo()
        if (res.code === 200) {
          const infoRef = await fetchUserById(res.data.user_info.id)
          setProfile(infoRef.data.user_info)
          localStorage.setItem(
            USER_ENUM.PROFILE,
            JSON.stringify(infoRef.data.user_info),
          )
        }
      }
    },
    [profile],
  )

  const onSetToken = React.useCallback((val: string) => {
    setToken(val)
    localStorage.setItem(USER_ENUM.TOKEN, val)
  }, [])

  const onFetchPermission = React.useCallback(async () => {
    try {
      const res = await fetchIsAdminRequest()
      if (res.code === 200) setPermission(res.data)
    } catch (error) {
      console.error(error)
    }
  }, [])

  const onSetTheme = React.useCallback((val: number) => {
    setTheme(val)
    localStorage.setItem(USER_ENUM.THEME, val.toString())

    const toggleDarkMode = () =>
      document.documentElement.classList.add("dark")

    const toggleWhiteMode = () =>
      document.documentElement.classList.remove("dark")

    const changeListener = (evt: MediaQueryListEvent) => {
      if (val !== 3) return

      evt.matches ? toggleDarkMode() : toggleWhiteMode()
    }

    if (val === 0) {
      toggleWhiteMode()
    } else if (val === 1) {
      toggleDarkMode()
    } else {
      const mediaQuery = window.matchMedia(
        "(prefers-color-scheme: dark)",
      )

      if (mediaQuery.matches) toggleDarkMode()
      else toggleWhiteMode()

      window
        .matchMedia("(prefers-color-scheme: dark)")
        .addEventListener("change", changeListener)
    }
  }, [])

  React.useEffect(() => onSetTheme(theme), [theme, onSetTheme])

  React.useEffect(() => {
    if (!/login/gi.test(window.location.pathname)) onGetProfile(true)
  }, [])

  return (
    <UserContext.Provider
      value={{
        token,
        theme: theme,
        profile: profile,
        permission: permission,
        onSetToken,
        onGetProfile,
        onFetchPermission: onFetchPermission,
        onSetTheme: onSetTheme,
      }}
    >
      {props.children}
    </UserContext.Provider>
  )
}
