import { normalizeSize } from "../util/class"

interface Props {
  className?: string
  size?: number | string
  border?: number | string
}
export default function Loading(props: Props) {
  const { border = 4, size = 72 } = props

  return (
    <div
      style={{
        height: normalizeSize(size),
        width: normalizeSize(size),
        borderWidth: normalizeSize(border),
      }}
      className={`${props.className} animate-spin rounded-full border-solid border-transparent border-t-[#409eff] bg-transparent`}
    />
  )
}
