import Loading from "@/components/ui/loading"
import React from "react"
import { Navigate, type RouteObject } from "react-router-dom"

const routes: RouteObject[] = []

const ctx = import.meta.glob("@/views/**/*.tsx")

function onSplitPath(path: string): string[] {
  const pathList = path.split("/").filter(item => item !== "")
  const last = pathList.pop()

  pathList.push(last?.split(".")[0] as string)
  return pathList
}

Object.entries(ctx).map(([item, component]) => {
  if (/component/gi.test(item)) return

  const splitPath = onSplitPath(item.split("/src/views")[1])

  let temp: RouteObject

  for (let i = 0; i < splitPath.length; i++) {
    const path = splitPath[i]

    if (i === 0) {
      const findIdx = routes.findIndex(
        item => item.path === `/${path}`,
      )

      if (findIdx === -1) {
        temp = { path: `/${path}`, children: [] }
        routes.push(temp)
      } else {
        temp = routes[findIdx]
      }
    } else if (i === splitPath.length - 1) {
      const LazyComponent = React.lazy(
        component as () => Promise<{
          default: React.ComponentType<unknown>
        }>,
      )

      temp!.element = (
        <React.Suspense
          fallback={
            <div className="flex h-full w-full items-center justify-center">
              <Loading size="10rem" />
            </div>
          }
        >
          <LazyComponent />
        </React.Suspense>
      )
    } else {
      const replacePath = (path: string) => path.replace(/index/g, "")

      const findIdx = temp!.children?.findIndex(
        item => item.path === replacePath(path),
      )

      if (findIdx === -1) {
        const temper = { path: replacePath(path), children: [] }
        temp!.children?.push(temper)
        temp = temper
      } else {
        temp = (temp!.children as RouteObject[])[findIdx!]
      }
    }
  }
})

routes.push({
  path: "*",
  element: <Navigate replace to="/app" />,
})

export default routes
