import { createBrowserRouter, RouterProvider } from "react-router-dom"
import MenuProvider from "./context/menu"
import UserProvider from "./context/user"
import routes from "./router"

export default function App() {
  return (
    <UserProvider>
      <MenuProvider>
        <RouterProvider router={createBrowserRouter(routes)} />
      </MenuProvider>
    </UserProvider>
  )
}
