import React from "react"

const MenuContext = React.createContext<{
  menu: UserMenuType[]
  onSetMenu: (menus: UserMenuType[]) => void
  flatMenu: CommonSelectItem<string>[]
}>({ menu: [], onSetMenu: () => void 0, flatMenu: [] })

export function useMenuContext() {
  const context = React.useContext(MenuContext)
  if (!context) {
    throw new Error("menu context is not in its field")
  }

  return context
}

interface Props extends React.PropsWithChildren {}
export default function MenuProvider(props: Props) {
  const [menu, setMenu] = React.useState<UserMenuType[]>([])
  const [flatMenu, setFlatMenu] = React.useState<
    CommonSelectItem<string>[]
  >([])

  const onSetMenu = React.useCallback((menus: UserMenuType[]) => {
    setMenu([...menus])
    setFlatMenu(
      menus.flatMap(val => [
        ...val.children.map(child => ({
          label: child.title,
          value: child.key,
        })),
      ]),
    )
  }, [])

  return (
    <MenuContext.Provider value={{ menu, onSetMenu, flatMenu }}>
      {props.children}
    </MenuContext.Provider>
  )
}
