import { API_BASE_URL } from "@/shared/API_BASE_URL"
import RequestConstruct from "./RequestConstruct"

const requestConstruct = new RequestConstruct()

/** @description 用户登录 */
export const userLoginRequest = (
  data: LoginFormType,
): Promise<
  CommonResponseType<{
    access_token: string
    expires_in: number
    token_type: string
    refresh_token: string
  }>
> => {
  const body = new FormData()
  body.append("grant_type", "password")
  body.append("username", data.username)
  body.append("password", data.password)

  return fetch(`${API_BASE_URL}auth/token`, {
    body,
    method: "post",
    redirect: "follow",
  }).then(response => response.json())
}

/** @description 获取全部用户 */
export function fetchAllUserRequest(): Promise<
  CommonResponseType<User[]>
> {
  return requestConstruct.jsonRequest({
    method: "get",
    url: "auth/user/index",
  })
}

/** @description 创建用户 */
export async function createUserRequest({
  name,
  username,
  subject_id,
  department_id,
}: CreateUserForm): Promise<CommonResponseType> {
  const body = new FormData()
  body.append("name", name)
  body.append("username", username)
  body.append("subject_id", `${subject_id}`)
  body.append("department_id", `${department_id}`)

  return requestConstruct.textRequest({
    body,
    method: "POST",
    url: "auth/user/create",
  })
}

/** @description 获取用户信息 */
export const fetchUserById = (
  id: number | string,
): Promise<CommonResponseType<{ user_info: UserInfo }>> =>
  requestConstruct.jsonRequest({
    method: "get",
    url: `auth/user/${id}/detail`,
  })
/** @description 用户更新接口 */
export const updateUserRequest = ({
  subject_id,
  department_id,
  is_active,
  admin_type,
  permissions,
  user_id,
  username,
  name,
  password,
  check_password,
  avatar_url,
}: {
  [key: string]: unknown
}): Promise<CommonResponseType> => {
  const body = new FormData()
  body.append("subject_id", `${subject_id}`)
  body.append("department_id", `${department_id}`)
  body.append("is_active", `${is_active}`)
  body.append("admin_type", `${admin_type}`)
  body.append("permissions", JSON.stringify(permissions))
  body.append("user_id", `${user_id}`)
  body.append("username", `${username}`)
  body.append("name", `${name}`)
  body.append("password", `${password ?? ""}`)
  body.append("check_password", `${check_password ?? ""}`)
  if (avatar_url) body.append("avatar_url", `${avatar_url}`)

  return requestConstruct.textRequest({
    body,
    method: "post",
    url: `auth/user/${user_id}/update`,
  })
}

/** @description Resets a user's password by sending an email with a reset link. */
export async function resetUserRequest(
  email: string,
): Promise<CommonResponseType & { msg: string }> {
  return requestConstruct.jsonRequest({
    body: JSON.stringify({ email }),
    method: "post",
    url: "auth/reset",
  })
}

export async function fetchUserTypeInfo(): Promise<
  CommonResponseType<CommonSelectItem[]>
> {
  return requestConstruct.jsonRequest({
    method: "get",
    url: "project/get_type_info",
  })
}

/** @description 用户充值 */
export const chargeUserRequest = (
  body: UserChargeForm,
): Promise<CommonResponseType> =>
  requestConstruct.jsonRequest({
    method: "post",
    url: "mj/recharge",
    body: JSON.stringify(body),
  })
/** @description 获取用户个人信息 */
export function fetchUserProfileInfo(): Promise<
  CommonResponseType<{
    user_info: UserProfileType
  }>
> {
  return requestConstruct.jsonRequest({
    url: `auth/user/info`,
  })
}
