import RequestConstruct from "./RequestConstruct"

const requestConstruct = new RequestConstruct()

/** @description 获取全部权限 */
export function fetchAllPermissions(): Promise<
  CommonResponseType<PermissionType[]>
> {
  return requestConstruct.jsonRequest({
    method: "get",
    url: "auth/permission/index",
  })
}

/** @description 获取权限信息 */
export function fetchPermissionData(
  userId?: string | number,
): Promise<CommonResponseType<UserPermission[]>> {
  return requestConstruct.jsonRequest({
    method: "get",
    url: `auth/permission/data?user_id=${userId || ""}`,
  })
}

/** @description 根据ID获取到权限Info */
export const fetchPermissionInfoById = (
  id: number | string,
): Promise<CommonResponseType<PermissionInfo>> =>
  requestConstruct.jsonRequest({
    method: "get",
    url: `auth/permission/${id}/detail`,
  })

/** @description 创建权限 */
export function createPermissionRequest(
  data: PermissionForm,
): Promise<CommonResponseType> {
  const body = new FormData()
  body.append("name", data.name)
  body.append("permissions", JSON.stringify(data.permissions))

  return requestConstruct.textRequest({
    body,
    method: "post",
    url: "auth/permission/create",
  })
}

/** @description 根据ID更新权限 */
export const updatePermissionRequest = (
  id: string | number,
  data: PermissionForm,
): Promise<CommonResponseType> => {
  const body = new FormData()
  body.append("name", data.name)
  body.append("permissions", JSON.stringify(data.permissions))

  return requestConstruct.textRequest({
    body,
    method: "post",
    url: `auth/permission/${id}/detail`,
  })
}

/** @description 是否是管理员 */
export function fetchIsAdminRequest(): Promise<
  CommonResponseType<UserPermissionType>
> {
  return requestConstruct.jsonRequest({
    method: "get",
    url: `project/super_check`,
  })
}

/** @description 路由鉴权 */
export function routeEachRequest(): Promise<
  CommonResponseType<{ permissions: string[] }>
> {
  return requestConstruct.jsonRequest({
    url: "auth/user/permissions",
  })
}
